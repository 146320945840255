import { call, put } from 'redux-saga/effects';

import { fetchWpPostsOk, fetchWpPostsError } from '../actions/index';

export function* fetchWpPosts(api) {
  try {
    const posts = yield call(api.fetchWpPosts);
    yield put(fetchWpPostsOk(posts));
  } catch(e) {
    yield put(fetchWpPostsError(e));
  }

}