import { createSelector } from 'reselect';

import { postsListSelector } from './posts';
import { pathNameSelector } from './router';
import { aboutPageSelector } from './pages';

export const fantaSeasSelector = createSelector(postsListSelector, posts => 
  posts.filter(post => post.details && post.details.artwork_type === 'fantaSeas'));


export const galleryMappedFantaSeasSelector = createSelector(fantaSeasSelector, photos => 
    photos
    .filter(post => post.details.image)
    .map(post => ({
            src: post.details.image.sizes.medium_large,
            width: post.details.image.width,
            height: post.details.image.height,
            id: post.ID,
            postname: post.post_name
        }))
);

export const activeFantaSeaSelector = createSelector(fantaSeasSelector, pathNameSelector, (photos, pathName) => {
    const photoName = pathName.split('/')[2];
    return photos.find(painting => painting.post_name === photoName);
})

export const fantaSeasAboutSelector = createSelector(aboutPageSelector, about => {
    return about && about.details && about.details.fanta_seas;
})