export const messagePlaceholder = piece => {
  return `Hi, I'm interested in ${piece.post_title}...`;
}

export const availabilityMapper = availability => {
  switch(availability) {
    case 'sold':
      return 'Sold';
    case 'nfs':
      return 'Not For Sale';
    case 'pending':
      return 'Sale Pending'
    default: 
      return 'Available';
  }
}