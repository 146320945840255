import { call, put, select } from 'redux-saga/effects';

import { fetchWpPagesOk, fetchWpPagesError } from '../actions/index';
import { aboutPageLoadedSelector } from '../selectors/pages';

export function* fetchWpPages(api) {
  try {
    const aboutLoaded = yield select(aboutPageLoadedSelector);
    if (!aboutLoaded) {
      const posts = yield call(api.fetchWpPages);
      yield put(fetchWpPagesOk(posts));
    }
   
  } catch(e) {
    yield put(fetchWpPagesError(e));
  }

}