import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from '../reducers';
import rootSaga from '../sagas';

export const history = createBrowserHistory();
const sagaMidddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const store = createStore(
  createRootReducer(history),
  {},
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      sagaMidddleware
    )
  )
);

sagaMidddleware.run(rootSaga);
