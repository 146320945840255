import React from 'react';
import { connect } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { navigateTo } from '../../actions';
import './index.css';

const Header = ({ navigateTo }) => {
  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" bg="light" variant="light">
    <div className="container">
      <Navbar.Brand className="Brand" onClick={() => navigateTo('/')}>
        {/* <img
          src={easel}
          width="20"
          height="30"
          className="d-inline-block align-top"
          alt="easel logo"
        />
        {' Becky Linhardt'} */}
        Becky Linhardt
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {/* <Nav.Link href="#" onClick={() => navigateTo('/')}>Home</Nav.Link>
          <Nav.Link href="#" onClick={() => navigateTo('/about')}>About</Nav.Link> */}
          <Nav.Link href="#" onClick={() => navigateTo('/paintings')}>Paintings</Nav.Link>
          {/* <Nav.Link href="#" onClick={() => navigateTo('/photos')}>Photographs</Nav.Link> */}
          <NavDropdown title="Photographs" id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => navigateTo('/light-studies')}>Light Studies</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigateTo('/fanta-seas')}>Fanta-Seas</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

  const mapDispatchToProps = {
    navigateTo
  };


export default connect(null, mapDispatchToProps)(Header);
