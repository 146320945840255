import React from 'react';
import { connect } from 'react-redux';
import Gallery from 'react-photo-gallery';
import Button from 'react-bootstrap/Button';

import { fetchWpPostsRequest, navigateTo } from '../../actions';
import { postsLoadedSelector } from '../../selectors/posts';
import { galleryMappedLightStudySelector, lightStudiesAboutSelector } from '../../selectors/lightStudies';
import { initialQuantity, additionalQuantity } from '../../constants/paintings';
import { fetchWpPagesRequest } from '../../actions/index';
import { photoDisclaimerSelector } from '../../selectors/photographs';

import './index.css';

class LightStudiesPage extends React.Component {
  state = {
    imageQuantity: initialQuantity
  }

  componentDidMount() {
    const {postsLoaded, fetchWpPosts, fetchWpPages } = this.props;

    if (!postsLoaded) {
      fetchWpPosts();
    }
    fetchWpPages();
  }

  handleClick = (e, target) => {
    this.props.navigateTo(`/light-study/${target.photo.postname}`);
  }

  showMore = () => { 
    this.setState({imageQuantity: this.state.imageQuantity + additionalQuantity })};

  render() {
    const { photos, about, disclaimer } = this.props;
    const { imageQuantity } = this.state;
    const filteredPhotos = photos.filter((painting, index) => index < imageQuantity);

    return (
      <div className="container Paintings-container">
        { about && photos && (
          <div>
            <div className="Paintings-aboutContainer">
              <h2>Light Studies</h2>
              <div className="Paintins-about">
                {about && about}
              </div>
              <div className="Paintings-disclaimer">
                {disclaimer && disclaimer.map(item => (
                  <div className="Paintings-disclaimerItem">{item.bullet_point}</div>
                ))}
              </div>
            </div>
            <Gallery photos={filteredPhotos} onClick={this.handleClick}/>
            {photos.length > imageQuantity && 
              <div className="Paintings-buttonContainer">
                <Button className="Paintings-showMoreButton" variant="outline-dark" size="sm" onClick={this.showMore}>Show More</Button>
              </div>
            }
          </div>)
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    postsLoaded: postsLoadedSelector(state),
    photos: galleryMappedLightStudySelector(state),
    about: lightStudiesAboutSelector(state),
    disclaimer: photoDisclaimerSelector(state)
  }
}

const mapDispatchToProps = {
  fetchWpPosts: fetchWpPostsRequest,
  fetchWpPages: fetchWpPagesRequest,
  navigateTo
}


export default connect(mapStateToProps, mapDispatchToProps)(LightStudiesPage);