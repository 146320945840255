import * as actionTypes from '../constants/actionTypes';

export const navigateTo = url => {
  return {
    type: actionTypes.NAVIGATE_TO,
    url
  }
}

export const fetchWpPostsRequest = () => {
  return {
    type: actionTypes.FETCH_WP_POSTS_REQUEST
  }
}

export const fetchWpPostsOk = posts => {
  return {
    type: actionTypes.FETCH_WP_POSTS_OK,
    posts
  }
}

export const fetchWpPostsError = error => {
  return {
    type: actionTypes.FETCH_WP_POSTS_ERROR,
    error
  }
}

export const fetchWpPagesRequest = () => {
  return {
    type: actionTypes.FETCH_WP_PAGES_REQUEST
  }
}

export const fetchWpPagesOk = pages => {
  return {
    type: actionTypes.FETCH_WP_PAGES_OK,
    pages
  }
}

export const fetchWpPagesError = error => {
  return {
    type: actionTypes.FETCH_WP_PAGES_ERROR,
    error
  }
}

export const sendEmailRequest = payload => {
  return {
    type: actionTypes.SEND_EMAIL_REQUEST,
    payload
  }
}

export const sendEmailOK = response => {
  return {
    type: actionTypes.SEND_EMAIL_OK,
    response
  }
}

export const sendEmailError = error => {
  return {
    type: actionTypes.SEND_EMAIL_ERROR,
    error
  }
}

export const resetContactForm = () => {
  return {
    type: actionTypes.RESET_CONTACT_FORM
  }
}