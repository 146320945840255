import { createSelector } from 'reselect';

import { postsListSelector } from './posts';
import { pathNameSelector } from './router';
import { aboutPageSelector } from './pages';

export const photographsSelector = createSelector(postsListSelector, posts => 
  posts.filter(post => post.details && post.artwork_type === 'photo'));


export const activePhotoSelector = createSelector(photographsSelector, pathNameSelector, (photos, pathName) => {

  const photoName = pathName.split('/')[2];
  return photos.find(photo => photo.post_name === photoName);
});


export const photoDisclaimerSelector = createSelector(aboutPageSelector, about => {
  return about && about.details && about.details.photo_disclaimer;
})