import axios from 'axios';

import { wordpressURL } from '../constants/api';

const embeddedResponseInterceptor = ({ data }) => data;

const api = axios.create({
  baseURL: wordpressURL
});

api.interceptors.response.use(embeddedResponseInterceptor);

export const fetchWpPosts = () => api.get('/sections/v1/posts');

export const fetchWpPages = () => api.get('/sections/v1/pages');
