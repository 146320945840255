import { createSelector } from 'reselect';

import { postsListSelector } from './posts';
import { pathNameSelector } from './router';
import { aboutPageSelector } from './pages';

export const paintingsSelector = createSelector(postsListSelector, posts => 
  posts.filter(post => post.details && post.details.artwork_type === 'painting'));

export const galleryMappedPaintingSelector = createSelector(paintingsSelector, paintings => 
  paintings
    .filter(post => post.details.image)
    .map(post => ({
            src: post.details.image.sizes.medium_large,
            width: post.details.image.width,
            height: post.details.image.height,
            id: post.ID,
            postname: post.post_name
        }))
);

export const activePaintingSelector = createSelector(paintingsSelector, pathNameSelector, (paintings, pathName) => {
  const paintingName = pathName.split('/')[2];
  return paintings.find(painting => painting.post_name === paintingName);
});

export const paintingsAboutSelector = createSelector(aboutPageSelector, about => {
  return about && about.details && about.details.paintings;
})