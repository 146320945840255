import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import marker from '../../content/marker30.png';
import artStudioLogo from '../../content/artStudioLogo.png';
import { fetchWpPagesRequest, navigateTo } from '../../actions/index';
import { aboutPageSelector } from '../../selectors/pages';
import './index.css'

const AnyReactComponent = ({ text }) => (
<div className="markerContainer">
  <img src={marker} alt="marker"/>
</div>
);


function AboutPage ({ fetchWpPagesRequest, navigateTo, pageData = {} }) {
  useEffect(() => {
   fetchWpPagesRequest();
  }, [fetchWpPagesRequest]);
  const { details = {} } = pageData;

  return (
    <div className="AboutPage">
      <div className="AboutPage-container">
        <div className="AboutPage-artLinksContainer">
          { details && details.paintings_image && (
            <div className="AboutPage-paintingsLinkContainer" onClick={() => navigateTo('/paintings')}>
              <h2>Paintings</h2>
              <img height="300" src={details.paintings_image.sizes.medium_large} alt="fanta seas link" /> 
            </div>
          )}
          { details && details.light_studies_image && (
            <div className="AboutPage-lightStudiesLinkContainer" onClick={() => navigateTo('/light-studies')}>
              <h2>Light Studies</h2>
              <img height="300" src={details.light_studies_image.sizes.medium_large} alt="light studies link" /> 
            </div>
          )}
          { details && details.fanta_seas_image && (
            <div className="AboutPage-paintingsLinkContainer" onClick={() => navigateTo('/fanta-seas')}>
              <h2>Fanta-Seas</h2>
              <img height="300" src={details.fanta_seas_image.sizes.medium_large} alt="fanta seas link" /> 
            </div>
          )}
        </div>
        <div className="AboutPage-personalDetails">
          <h2>About Me</h2>
          <div>{details && details.personal_details}</div>
        </div>

        <div className="AboutPage-blogContainer">
          <h2>My Blog</h2>
          <div>{details && details.blog_description}</div>
          <a target="_blank" rel="noopener noreferrer" href={details && details.blog_url}>
            {details && details.blog_url}
          </a>
        </div>

        <div className="AboutPage-locationDetailsContainer">
          <div className="AboutPage-locationDetails">
            <h2 className="AboutPage-sectionTitle">Location Details</h2>
            <div>{details && details.location_details}</div>
            <div className="AboutPage-addressContainer">
              <div className="AboutPage-studioLogoContainer">
                <a href="http://studiosonmain.com" target="_blank" rel="noopener noreferrer">
                  <img src={artStudioLogo} alt="studio-logo" className="AboutPage-studioLogo"/>
                </a>
              </div>
              <div className="AboutPage-addressDetails">
                <div className="AboutPage-address">{details && details.address}</div>
                <div className="AboutPage-cityState">{ details && `${details.city}, ${details.state}` }</div>
                <div className="AboutPage-zipCode">{details && details.zip_code}</div>
              </div>
            </div>
          </div>
          <div className="AboutPage-mapContainer">
            <GoogleMapReact
              bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API }}
              defaultCenter={{
                lat: 39.267535,
                lng: -84.265234
              }}
              defaultZoom={15}
              >
              <AnyReactComponent
                  lat={39.2682}
                  lng={-84.2655}
                  text="Studio"
                />
              </GoogleMapReact>
          </div>
        </div>
        <div className="AboutPage-resumeContainer">
          <h2 className="AboutPage-resumeTitle">Resume</h2>
          <div className="AboutPage-educationContainer">
            {details && details.education}
          </div>
          <div className="AboutPage-publicArtContainer">
            <h5 className="AboutPage-publicArtTitle">Public Art/Commissions</h5>
            {details && details.public_art && details.public_art.map((piece, index) => (
              <div className="AboutPage-publicArtPiece" key={`${piece.title}${index}`}>
                <div className="AboutPage-publicArtPieceTitle">
                  {piece.title}
                </div>
                <div className="AboutPage-publicArtPieceProgram">
                  {`${piece.program} - ${piece.year}`}
                </div>
                <div className="AboutPage-publicArtPieceLocation">
                  {piece.location}
                </div>
                <div className="AboutPage-publicArtPieceCityState">
                  {`${piece.city}, ${piece.state}`}
                </div>
                <div className="AboutPage-publicArtPieceInformation">
                  {piece.information}
                </div>
              </div>
            ))}
          </div>
          <div className="AboutPage-competitionsContainer">
            <h5 className="AboutPage-competitionsTitle">Recent Exhibitions</h5>
            {details && details.competitions && details.competitions.map((piece, index) => (
              <div className="AboutPage-competitionPiece" key={`${piece.title}${index}`}>
                <div className="AboutPage-competitionPieceTitle">
                  {piece.title}
                </div>
                <div className="AboutPage-competitionPieceLocation">
                  {piece.location}
                </div>
                <div className="AboutPage-competitionPieceCityState">
                  {`${piece.city}, ${piece.state}`}
                </div>
                <div className="AboutPage-competitionPieceDate">
                  {piece.date}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    pageData: aboutPageSelector(state)
  }
}

const mapDispatchToProps = {
  fetchWpPagesRequest,
  navigateTo
}


export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);
