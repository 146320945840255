import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { navigateTo, sendEmailRequest } from '../../actions';
import { messagePlaceholder } from '../../utils/contactForm';
import { emailLoadingSelector, emailLoadedSelector } from '../../selectors/email';
import Loading from '../Loading';
import greenCheck from '../../content/greenCheck.png';

import './index.css';

class ContactForm extends React.Component {
  state = {
    email: '',
    name: '',
    message: ''
  }

  handleFormChange = (fieldName, e) => {
    this.setState({ [fieldName]: e.target.value});
  } 

  render() {
    const { piece, sendEmail, loading, loaded } = this.props;
    const { email, name, message } = this.state;

    return (
      <Card>
        <Card.Body>
          {!loading && !loaded && (
            <Form>
             <Form.Group controlId="emailField">
               <Form.Label>Email address</Form.Label>
               <Form.Control type="email" placeholder="Enter your email" value={email} onChange={this.handleFormChange.bind(this, 'email')} />
             </Form.Group>
             <Form.Group controlId="nameField">
               <Form.Label>Name</Form.Label>
               <Form.Control type="text" placeholder="Enter your name" value={name} onChange={this.handleFormChange.bind(this, 'name')} />
             </Form.Group>
             <Form.Group controlId="messageField">
               <Form.Label>Message</Form.Label>
               <Form.Control as="textarea" rows="3" placeholder={messagePlaceholder(piece)} value={message} onChange={this.handleFormChange.bind(this, 'message')} />
             </Form.Group>
             <Button onClick={() => sendEmail({email, name, message, artworkTitle: piece.post_title})}>Send</Button>
            </Form>
          )}
          {loading && !loaded && (
            <div className="loadingContainer">
               <Loading message="Sending..." />
            </div>
           
          )}
          {!loading && loaded && (
            <div className="loadedContainer">
              <div className="loadedHeader">
                <img src={greenCheck} height={30} width={30} alt="green check" />
                <h4 className="heading">Sent!</h4>
              </div>
              <div>
                Thank you for your message. I will be in contact shortly!
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  }
};

  const mapStateToProps = state => {
    return {
      loading: emailLoadingSelector(state),
      loaded: emailLoadedSelector(state)
    }
  };

  const mapDispatchToProps = {
    navigateTo,
    sendEmail: sendEmailRequest
  };

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
