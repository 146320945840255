import * as actionTypes from '../constants/actionTypes';

const initialState = {
  loading: false,
  loaded: false,
}


export default (state = initialState, { type }) => {
  switch(type) {
    case actionTypes.SEND_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.SEND_EMAIL_OK:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case actionTypes.RESET_CONTACT_FORM:
      return {
        ...state,
        loaded: false    
      };
    default:
      return {
        ...state
      }
  }
}