import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import ListGroup from 'react-bootstrap/ListGroup';

import ContactForm from '../../components/ContactForm';
import { postsLoadedSelector } from '../../selectors/posts';
import { emailLoadedSelector } from '../../selectors/email';
import { activePaintingSelector } from '../../selectors/paintings';
import { fetchWpPostsRequest, navigateTo } from '../../actions';
import { availabilityMapper } from '../../utils/contactForm';
import './index.css';

class PaintingDetails extends React.Component {
  state = {
    contactFormOpen: false
  }

  componentDidMount() {
    const {postsLoaded, fetchWpPosts} = this.props;

    if (!postsLoaded) {
      fetchWpPosts();
    }
  }
  
  render() {
    const { activePainting, navigateTo, emailLoaded  } = this.props;
    const { contactFormOpen } = this.state;

    return (
      <div className="container ActivePainting-outerContainer">
        { activePainting &&
          <div>
            <div className="ActivePainting-postTitleContainer">
              <h1>{activePainting.post_title}</h1>
            </div>
            <div className="ActivePainting-container">
              <div className="ActivePainting-imageContainer">
                <img
                  className="ActivePainting-image"
                  src={activePainting.details.image.url} 
                  alt={activePainting.post_name} />
              </div>
              <div className="ActivePainting-imageDetailsContainer">
                <div className="ActivePainting-detailsCard"> 
                  <Card>
                    <Card.Header>Details</Card.Header>
                    <Card.Body>
                      <Card.Text>{activePainting.details.description}</Card.Text>
                      <Card.Title>Dimensions</Card.Title>
                      <Card.Text>
                      {`${activePainting.details.width}" width, ${activePainting.details.height}" height, ${activePainting.details.thickness}" depth`}
                      </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem>{`${activePainting.details.paint_type} on ${activePainting.details.medium}`}</ListGroupItem>
                      <ListGroupItem>{`${activePainting.details.year}`}</ListGroupItem>
                      <ListGroupItem 
                          className={activePainting.details.availability === 'sold' ? "ActivePainting-sold" : "ActivePainting-availability"}
                        >
                            {availabilityMapper(activePainting.details.availability)}
                        </ListGroupItem>
                      { activePainting.details.availability === 'available' && !emailLoaded && (
                        <ListGroupItem>
                          <Button onClick={() => this.setState({contactFormOpen: !contactFormOpen})}>{contactFormOpen ? `Cancel`: `Get More Information`}</Button>
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </Card>
                  {contactFormOpen &&
                    <div className="ActivePainting-contactFormContainer">
                      <ContactForm piece={activePainting} />
                    </div>
                  }
                </div>
                {activePainting.details && activePainting.details.part_of_set &&
                  <div className="ActivePainting-setCard">
                    <Card>
                      <Card.Header>Part of a Set</Card.Header>
                      <Card.Body>
                        <Card.Text>This painting can be sold separately or as a set</Card.Text>
                        <Card.Img className="ActivePainting-setImage" src={activePainting.details.full_set_image.url} onClick={() => navigateTo(`/painting/${activePainting.details.accompanying_paintings[0].post_name}`)} />
                      </Card.Body>
                    </Card>
                  </div>
                }
              </div>
              </div>
          </div>
        }
        <div className="ActivePainting-backButton">
          <Button onClick={() => navigateTo('/paintings')}>Back to Paintings</Button>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  activePainting: activePaintingSelector(state),
  postsLoaded: postsLoadedSelector(state),
  emailLoaded: emailLoadedSelector(state)
})

const mapDispatchToProps = {
  fetchWpPosts: fetchWpPostsRequest,
  navigateTo
}

export default connect(mapStateToProps, mapDispatchToProps)(PaintingDetails);