import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch,  } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import { store, history } from './store';
import Header from './components/Header';
// import HomePage from './pages/Home';
import AboutPage from './pages/About';
import PaintingsPage from './pages/Paitings';
// import PhotographyPage from './pages/Photographs';
import FantaSeasPage from './pages/FantaSeas';
import PaintingDetails from './pages/PaintingDetails';
import FantaSeasDetails from './pages/FantaSeasDetails';
import LightStudiesPage from './pages/LightStudies';
import LightStudyDetails from './pages/LightStudyDetails';

import './index.css';



ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className="AppContainer">
        <Header />
        <Switch>
          <Route exact path="/" component={AboutPage} />
          {/* <Route exact path="/about" component={AboutPage} /> */}
          <Route exact path="/paintings" component={PaintingsPage} />
          {/* <Route exact path="/photos" component={PhotographyPage} /> */}
          <Route path="/painting/:name" component={PaintingDetails} />
          <Route exact path="/fanta-seas" component={FantaSeasPage} />
          <Route path="/fanta-sea/:name" component={FantaSeasDetails} />
          <Route exact path="/light-studies" component={LightStudiesPage} />
          <Route path="/light-study/:name" component={LightStudyDetails} />
        </Switch>
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
