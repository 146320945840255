import { takeLatest} from 'redux-saga/effects';

import * as api from '../api';
import * as actionTypes from '../constants/actionTypes';
import { fetchWpPosts } from './posts';
import { fetchWpPages } from './pages';
import { navigateTo } from './navigation';
import { sendEmail } from './email';

export default function* root() {
  yield takeLatest(
    actionTypes.NAVIGATE_TO,
    navigateTo
  )

  yield takeLatest(
    actionTypes.FETCH_WP_POSTS_REQUEST,
    fetchWpPosts,
    api
  )

  yield takeLatest(
    actionTypes.FETCH_WP_PAGES_REQUEST,
    fetchWpPages,
    api
  )

  yield takeLatest(
    actionTypes.SEND_EMAIL_REQUEST,
    sendEmail
  )
}

