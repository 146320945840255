import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import postsReducer from '../reducers/posts';
import pagesReducer from '../reducers/pages';
import emailReducer from '../reducers/email';

export default (history) => combineReducers({
  router: connectRouter(history),
  posts: postsReducer,
  pages: pagesReducer,
  email: emailReducer
});
