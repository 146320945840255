export const FETCH_WP_POSTS_REQUEST = 'FETCH_WP_POSTS_REQUEST';
export const FETCH_WP_POSTS_OK = 'FETCH_WP_POSTS_OK';
export const FETCH_WP_POSTS_ERROR = 'FETCH_WP_POSTS_ERROR';

export const FETCH_WP_PAGES_REQUEST = 'FETCH_WP_PAGES_REQUEST';
export const FETCH_WP_PAGES_OK = 'FETCH_WP_PAGES_OK';
export const FETCH_WP_PAGES_ERROR = 'FETCH_WP_PAGES_ERROR';

export const NAVIGATE_TO = 'NAVIGATE_TO';


export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_OK = 'SEND_EMAIL_OK';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';

export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';