import { createSelector } from 'reselect';

import { postsListSelector } from './posts';
import { pathNameSelector } from './router';
import { aboutPageSelector } from './pages';

export const lightStudiesSelector = createSelector(postsListSelector, posts => 
  posts.filter(post => post.details && post.details.artwork_type === 'lightStud'));

export const galleryMappedLightStudySelector = createSelector(lightStudiesSelector, photos => 
    photos
        .filter(post => post.details.image)
        .map(post => ({
                src: post.details.image.sizes.medium_large,
                width: post.details.image.width,
                height: post.details.image.height,
                id: post.ID,
                postname: post.post_name
            }))
);

export const activeLightStudySelector = createSelector(lightStudiesSelector, pathNameSelector, (photos, pathName) => {
    const photoName = pathName.split('/')[2];
    return photos.find(photo => photo.post_name === photoName);
})

export const lightStudiesAboutSelector = createSelector(aboutPageSelector, about => {
    return about && about.details && about.details.light_studies;
})