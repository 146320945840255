import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import ListGroup from 'react-bootstrap/ListGroup';

import ContactForm from '../../components/ContactForm';
import { postsLoadedSelector } from '../../selectors/posts';
import { emailLoadedSelector } from '../../selectors/email';
import { activeLightStudySelector } from '../../selectors/lightStudies';
import { fetchWpPostsRequest, navigateTo } from '../../actions';
import { availabilityMapper } from '../../utils/contactForm';
import './index.css';

class LightStudyDetails extends React.Component {
  state = {
    contactFormOpen: false
  }

  componentDidMount() {
    const {postsLoaded, fetchWpPosts} = this.props;

    if (!postsLoaded) {
      fetchWpPosts();
    }
  }
  
  render() {
    const { activePhoto, navigateTo, emailLoaded  } = this.props;
    const { contactFormOpen } = this.state;

    return (
      <div className="container ActivePhoto-outerContainer">
        { activePhoto &&
          <div>
            <div className="ActivePhoto-postTitleContainer">
              <h1>{activePhoto.post_title}</h1>
            </div>
            <div className="ActivePhoto-container">
              <div className="ActivePhoto-imageContainer">
                <img
                  className="ActivePhoto-image"
                  src={activePhoto.details.image.url} 
                  alt={activePhoto.post_name} />
              </div>
              <div className="ActivePhoto-imageDetailsContainer">
                <div className="ActivePhoto-detailsCard"> 
                  <Card>
                    <Card.Header>Details</Card.Header>
                    <Card.Body>
                      <Card.Text>{activePhoto.details.description}</Card.Text>
                      <Card.Title>Dimensions</Card.Title>
                      <Card.Text>
                      {`${activePhoto.details.width}" width, ${activePhoto.details.height}" height`}
                      </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                      { activePhoto.details.year && (<ListGroupItem>{`${activePhoto.details.year}`}</ListGroupItem>)}
                      <ListGroupItem 
                          className={activePhoto.details.availability === 'sold' ? "ActivePhoto-sold" : "ActivePhoto-availability"} 
                        >
                          {availabilityMapper(activePhoto.details.availability)}
                        </ListGroupItem>
                      { activePhoto.details.availability === 'available' && !emailLoaded && (
                        <ListGroupItem>
                          <Button onClick={() => this.setState({contactFormOpen: !contactFormOpen})}>{contactFormOpen ? `Cancel`: `Get More Information`}</Button>
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </Card>
                  {contactFormOpen &&
                    <div className="ActivePhoto-contactFormContainer">
                      <ContactForm piece={activePhoto} />
                    </div>
                  }
                </div>
                {activePhoto.details && activePhoto.details.part_of_set &&
                  <div className="ActivePhoto-setCard">
                    <Card>
                      <Card.Header>Part of a Set</Card.Header>
                      <Card.Body>
                        <Card.Text>This Photo can be sold separately or as a set</Card.Text>
                        <Card.Img className="ActivePhoto-setImage" src={activePhoto.details.full_set_image.url} onClick={() => navigateTo(`/light-study/${activePhoto.details.accompanying_photos[0].post_name}`)} />
                      </Card.Body>
                    </Card>
                  </div>
                }
              </div>
              </div>
          </div>
        }
        <div className="ActivePhoto-backButton">
          <Button onClick={() => navigateTo('/light-studies')}>Back to Light Studies</Button>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  activePhoto: activeLightStudySelector(state),
  postsLoaded: postsLoadedSelector(state),
  emailLoaded: emailLoadedSelector(state)
})

const mapDispatchToProps = {
  fetchWpPosts: fetchWpPostsRequest,
  navigateTo
}

export default connect(mapStateToProps, mapDispatchToProps)(LightStudyDetails);