import React from 'react';
import './index.css';

const Loading = ({message}) => (
    <div className="Loading-container">
        <h3>{message ? message : "Loading..."}</h3>
        <div class="loader"></div>
    </div>
);

export default Loading;
