import { createSelector } from 'reselect';
import rootSelector from './root';

export const postsSelector = createSelector(rootSelector, root => root.posts);

export const postsListSelector = createSelector(postsSelector, posts => posts.list);

export const postsLoadingSelector = createSelector(postsSelector, posts => posts.loading);

export const postsLoadedSelector = createSelector(postsSelector, posts => posts.loaded);
