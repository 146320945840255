import { put } from 'redux-saga/effects';
import emailjs from 'emailjs-com';

import { sendEmailOK, sendEmailError } from '../actions/index';

export function* sendEmail({payload}) {
    const templateId = 'template_Q6h9KL4F';
    const serviceId = 'default_service';
    const userId = process.env.REACT_APP_EMAIL_API_KEY;

  try {
    const response = yield emailjs.send(serviceId,templateId, payload, userId)
    yield put(sendEmailOK(response));
  } catch(e) {
    yield put(sendEmailError(e));
  }
}