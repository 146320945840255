import * as actionTypes from '../constants/actionTypes';

const initialState = {
  loading: false,
  loaded: false,
  list: []
}


export default (state = initialState, { type, pages }) => {
  switch(type) {
    case actionTypes.FETCH_WP_PAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.FETCH_WP_PAGES_OK:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: pages
      }
    default:
      return {
        ...state
      }
  }
}